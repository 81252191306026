<template>
  <Component :is="blueprintComponent" v-if="blueprintComponent" :page="page" />
</template>

<script setup lang="ts">
import type { Ref } from 'vue'
import { useChangeCase } from '@vueuse/integrations/useChangeCase'

const props = defineProps<{
  page?: {
    blueprint: string
  }
}>()

const blueprintComponent: Ref<string | null> = props.page?.blueprint
  ? useChangeCase('blueprint-' + props.page.blueprint, 'pascalCase')
  : ref(null)
</script>
